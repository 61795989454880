import { render, staticRenderFns } from "./Auditoria.vue?vue&type=template&id=180a66cf&scoped=true"
import script from "./Auditoria.vue?vue&type=script&lang=js"
export * from "./Auditoria.vue?vue&type=script&lang=js"
import style0 from "./Auditoria.vue?vue&type=style&index=0&id=180a66cf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "180a66cf",
  null
  
)

export default component.exports