<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Noviembre 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
        <!-- Filtros para buscar relaciones -->
        <section class="d-flex">
            <!-- Filtrar por orden -->
            <v-text-field class="me-2 filtro" v-model="buscar.orden" label="Orden" outlined dense
                hide-details @keypress="validarNumeros">
            </v-text-field>
            <!-- Filtrar por descripcion -->
            <v-text-field class="me-2 filtro" v-model="buscar.descripcion" label="Descripción" outlined dense
                hide-details @keypress="validarLetras">
            </v-text-field>
            <!-- Filtrar por estado-->
            <v-select class="me-2 filtro" v-model="buscar.estado" label="Estado" outlined dense 
                hide-details :items="estados" :menu-props="{ offsetY: true }">
            </v-select>
            <!-- Este botón abre un diálogo para agregar una relación -->
            <div>
                <v-tooltip left color="success" :open-on-focus="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small fab color="success" v-bind="attrs" v-on="on"
                            @mousedown.prevent="dialogoAuditoria = true">
                            <v-icon> add </v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar</span>
                </v-tooltip>
            </div>
        </section>

        <!-- Diálogo para crear un estado auditoría-->
        <v-dialog v-model="dialogoAuditoria" transition="dialog-bottom-transition" max-width="30rem" persistent :editar="false">
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6"> {{ tituloDialogo }} </span>
                </v-card-title>
                <v-card-text class="dialogo">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <!-- Formulario para registrar la relación -->
                        <v-form lazy-validation>
                            <!-- Campo para ingresar la descripción del estado -->
                            <div class="d-flex">
                                <validation-provider class="campos" v-slot="{ errors }"
                                    :rules="{ required: true }">
                                    <v-text-field v-model="crearAuditoria.descripcionAuditoria" label="Descripción" 
                                        required dense outlined :error-messages="errors"  @keypress="validarLetras">
                                    </v-text-field>
                                </validation-provider>
                                <!-- Tooltip para mostrar información sobre la descripción del estado-->
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Descripción.
                                        </span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <!-- Campo para ingresar el último estado -->
                            <div class="d-flex filas me-2">
                                <validation-provider v-slot="{ errors }" :rules="{ required: true }"
                                    class="campos">
                                    <v-autocomplete v-model="crearAuditoria.ultimoEstado" label="Último estado" 
                                        required dense outlined :error-messages="errors" :items="opciones.estados" 
                                        :menu-props="{ maxHeight: 200, offsetY: true, maxWidth: 405 }" @click="listarAuditorias">
                                    </v-autocomplete>
                                </validation-provider>
                                <!-- Tooltip para mostrar información sobre el campo de usuario -->
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Último estado.</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <!-- Campo para ingresar el orden -->
                            <div class="d-flex filas me-2">
                                <validation-provider v-slot="{ errors }" 
                                    :rules="{ required: true }" class="campos">
                                    <v-text-field v-model="crearAuditoria.orden" label="Orden"
                                        :error-messages="errors" dense outlined @keypress="validarNumeros"
                                        :menu-props="{ maxHeight: 200, offsetY: true, maxWidth: 405 }">
                                    </v-text-field>
                                </validation-provider>
                                <!-- Tooltip para mostrar información sobre el campo estado auditoría -->
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>El orden del estado.</span>
                                    </v-tooltip>
                                </div>
                                <validation-provider class="campos ms-2" v-slot="{ errors }"
                                    :rules="{ required: true }">
                                    <v-text-field v-model="crearAuditoria.color" label="Color" required dense readonly
                                        outlined :error-messages="errors" @click="mostrarColorPicker = true">
                                    </v-text-field>
                                </validation-provider>
                                <!-- Tooltip para mostrar información sobre el campo estado auditoría -->
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Color del estado.</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <div class="d-flex justify-end">
                                <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
                                <v-btn color="success" :disabled="invalid || botonValidacion" text depressed
                                    @click="!editar ? guardarAuditoria() : editarAuditoria()">
                                    Guardar
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="mostrarColorPicker" transition="dialog-bottom-transition" max-width="21.8rem" persistent>
            <v-card title="Color">
                <v-card-text class="pt-5">
                    <v-color-picker class="pb-5" v-model="crearAuditoria.color" show-swatches swatches-max-height="80px">
                    </v-color-picker>
                    <v-card-actions class="justify-end" style="padding: 0;">
                        <v-btn text color="primary" @click="mostrarColorPicker = false">Ok</v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-data-table class="elevation mt-4" :items="tablaAuditoria.items" fixed-header :headers="tablaAuditoria.headers"
            :loading="tablaAuditoria.loading" :page.sync="tablaAuditoria.page" :server-items-length="tablaAuditoria.totalPage"
            :footer-props="tablaAuditoria.footerProps" :items-per-page.sync="tablaAuditoria.itemsPerPage" height="64vh">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.idAuditoria">
                        <td class="text-center"> {{ item.orden }} </td>
                        <td>{{ item.descripcionAuditoria }} </td>
                        <td class="text-center"> {{ item.ultimoEstado }} </td>
                        <td class="text-center"> 
                            <v-icon size="xx-large" :color="item.color">square</v-icon></td>
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'">
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td class="text-center">
                            <v-btn icon medium>
                                <v-icon color="orange"> border_color </v-icon>
                            </v-btn>
                            <v-btn icon>
                                <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                                    {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- dialogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="25rem" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6">
                        {{ mensajeEstado }}
                    </span>
                </v-card-title>
                <v-card-text class="dialogo">
                    <div class="d-flex justify-end">
                        <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn color="success" depressed text>Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "@/router/role.js";
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

// validaciones con vee-validate
setInteractionMode('eager')
extend('required', {
    ...required,
    message: 'Este campo es requerido.',
});
// validaciones con vee-validate---fin
export default {
    name: "Auditoria",
    props: {
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            tituloDialogo: 'Crear estado auditoría',
            mostrarColorPicker: false,
            color: '',
            crearAuditoria: {
                estado: '',
                descripcionAuditoria: '',
                ultimoEstado: '',
                color: ''
            },
            buscar: {
                descripcion: '',
                orden: '',
                estado: ''
            },
            estados: [
                { text: 'Todos', value: '' },
                { text: 'Activo', value: false },
                { text: 'Inactivo', value: true },
            ],
            dialogoAuditoria: false,
            botonValidacion: false,
            opciones: {
                estados: []
            },
            eliminado: false,
            lastmodifiedby: '',
            tablaAuditoria: {
                loading: false,
                items: [],
                headers: [
                    { text: "ORDEN", align: "center", width: "10%", sortable: false },
                    { text: "DESCRIPCIÓN", width: "35%", sortable: false },
                    { text: "ÚLTIMO ESTADO", align: "center", width: "10%", sortable: false },
                    { text: "COLOR", align: "center", width: "15%", sortable: false },
                    { text: "ESTADO", width: "15%", sortable: false },
                    { text: "ACCIONES", align: "center", width: "15%", sortable: false },
                ],
                footerProps: {
                    'items-per-page-options': [10, 20, 30, 40],
                    'items-per-page-text': 'Items por página:',
                    'show-current-page': true,
                    'show-first-last-page': true
                },
                itemsPerPage: 10,
                page: 1,
                totalPage: 0
            },
            auditoriaSeleccionada: {},
            dialogoCambiarEstado: false,
            mensajeEstado: '',
            userRoles: {},
            roles: Role,
            editar: false
        }
    },
    created() {
        this.userRoles = this.auth.roles;
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
    },
    watch: {
        'tablaAuditoria.page': function () {
            this.paginarAuditoria();
        },
        'tablaAuditoria.itemsPerPage': function () {
            this.tablaAuditoria.page = 1;
            this.paginarAuditoria();
        },
        'buscar.descripcion': function () {
            this.tablaAuditoria.page = 1;
            this.paginarAuditoria();
        },
        'buscar.orden': function () {
            this.tablaAuditoria.page = 1;
            this.paginarAuditoria();
        },
        'buscar.estado': function () {
            this.tablaAuditoria.page = 1;
            this.paginarAuditoria();
        }
    },
    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),
        /**
         * Lista las auditorias en una tabla mediante una solicitud HTTP GET 
         * con los siguientes parámetros:
         * page - página actual
         * size - tamaño de la página o número de elementos por página
         */
        paginarAuditoria() {
            this.tablaAuditoria.loading = true;
            this.$http.get(`msa-administration/auditoria/paginarAuditoria`, {
                params: {
                    page: this.tablaAuditoria.page -1,
                    size: this.tablaAuditoria.itemsPerPage,
                    descripcion: `${this.buscar.descripcion.toLowerCase()}`,
                    orden: `${this.buscar.orden}`,
                    eliminado: `${this.buscar.estado}`,
                    idEmpresa: this.enterprise.code
                }
            }).then((response) => {
                this.tablaAuditoria.items = response.data.content;
                this.tablaAuditoria.totalPage = response.data.totalElements;
                this.tablaAuditoria.loading = false;
            }).catch((error) => {
                console.log(error);
                this.tablaAuditoria.loading = false;
            })
        },
        /**
         * Lista las auditorias existentes para crear una nueva auditoria
         */
        listarAuditorias() {
            this.$http.get(`msa-administration/auditoria/listarAuditoria`, {
                params: {
                    idEmpresa: this.enterprise.code,
                    eliminado: false
                }
            })
            .then((response) => {
                response.data.forEach((item) => {
                    this.opciones.estados.push({
                        text: item.descripcionAuditoria,
                        value: item.orden
                    })
                })
            }).catch((error) => {
                console.log(error);
            })
        },
        /**
         * Método que solo permite el ingreso de letras
         * si la tecla presionada no corresponde a la condición, se evita que se ingrese el caracter
         * lo que hace que no se ingrese el valor de la tecla
         * @param {evento} event - El evento del teclado que se produce al escribir en el campo de entrada.
         */
        validarLetras(event) {
            const key = event.key;
            if (!key.match(/[a-zA-ZáéíóúÁÉÍÓÚ .-]/)) {
                event.preventDefault();
            }
        },
        /**
         * Método que solo permite el ingreso de números
         * si la tecla presionada no corresponde a la condición, se evita que se ingrese el caracter
         * lo que hace que no se ingrese el valor de la tecla
         * @param {evento} event - El evento del teclado que se produce al escribir en el campo de entrada.
         */
        validarNumeros(event) {
            const key = event.key;
            if (key.match(/\D/)) {
                event.preventDefault();
            }
        },
        /**
         * Abre el diálogo de editar con los datos del objeto seleccionado(datos actuales de la auditoria)
         * @param {*} item - Objeto que contiene los datos de la auditoria a editar.
         */
        abrirDialogoEditar(item) {
            this.crearAuditoria = { ...item }
            this.listarAuditorias();
            this.editar = true;
            this.tituloDialogo = 'Editar estado auditoría';
            this.dialogoAuditoria = true;
        },
        /**
         * Abre un diálogo para cambiar el estado de un estado de auditoría
         * @param {Object} item - El objeto que representa el estado auditoría seleccionada
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true
            this.auditoriaSeleccionada = item;
            this.mensajeEstado = this.auditoriaSeleccionada.eliminado === false ? '¿Desea inactivar el estado auditoría?' : '¿Desea activar el estado auditoría?';
            setTimeout(() => {
                this.mensajeEstado = this.auditoriaSeleccionada.eliminado === false ? '¿Desea inactivar el estado auditoría?' : '¿Desea activar el estado auditoría?';
            }, 1000);
        },
        /**
         * Método que se encarga de limpiar o restablecer varios valores a su estado inicial.
         */
        clear() {
            this.dialogoAuditoria = false;
            this.crearAuditoria.descripcionAuditoria = '';
            this.crearAuditoria.ultimoEstado = '';
            this.crearAuditoria.orden = '';
            this.crearAuditoria.color = '';
            this.$refs.observer.reset();
            this.opciones.orden = [];
            this.opciones.estados = [];
            this.botonValidacion = false;
        },
    },
    mounted() {
        this.lastmodifiedby = this.auth.username.toUpperCase();
        this.paginarAuditoria();
    },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
    padding: 1rem;
    height: 86vh;
}
.filtro {
    width: 20%;
}
.encabezado {
    background-color: #1867c0;
    color: white;
}
.dialogo {
    padding-top: 20px !important;
}
.filas {
    width: 100% !important;
}
.campos {
    width: 100%;
}
.tooltip {
    height: 2.5rem;
    margin-left: 3px;
}
::v-deep .elevation .v-data-footer {
    width: 100%;
}
::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
</style>